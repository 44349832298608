/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker,
    Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal, Tabs
} from 'antd';

import ModalAddAccount from '../../../components/Ants/Modal/ModalAddAccount';


import { apiClient } from '../../../api';
import { root, getGenToken } from "../../../config";


import { token, validateEmail, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import Account from '../Account';
import SubAccounts from '../SubAccounts';
import Users from '../Users';
import CreditsMovement from '../CreditsMovement';
import ConfigCreditAlert from '../ConfigCreditAlert';
import TokenAuth from '../TokenAuth/TokenAuth';

moment.tz.setDefault("Asia/Bangkok");

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class AccountList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            search: {
                name: "",
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            MadalAddAccountvisible: false,
            accname: "",
            email: "",
            username: "",
            password: "",
            paytype: 1,
            addstatusacc: 1,
            creactsubeaccount: true,
            createaccount: false,
            type: 0,
            userGenToken: false,
            emailError: false,
            errorMessage: "",
            dsender: "",
            listview: "block",
            detailview: "none",
            subaccount: false,
            settabs: "account",
            creditaccId: undefined,
            creditaccName: "",
            creditmove: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.accountCreate = this.accountCreate.bind(this);
        this.Getpermission = this.Getpermission.bind(this);
        this.callbackView = this.callbackView.bind(this);
        this.setCreditAccId = this.setCreditAccId.bind(this);
    }

    componentDidMount() {
        this.Getpermission();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "createacc") {
            this.setState({ action: "" });
            this.accountCreate();
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    handleOk() {
        this.setState({
            MadalAddAccountvisible: false, usercreate: ""
        });
    };

    accountCreate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                name: this.state.accname,
                email: this.state.email,
                username: this.state.username,
                password: this.state.password,
                paytype: this.state.paytype,
                status: this.state.addstatusacc,
                creactsubeaccount: this.state.creactsubeaccount == 0 ? false : (this.state.creactsubeaccount == 1 ? true : this.state.creactsubeaccount),
                sender: this.state.dsender,
            });

            apiClient.post('api/usersmanage/AccountCreate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    if (json.resultCode == "000") {
                        this.onSearch();
                        this.handleOk();

                        this.setState({
                            accname: "", email: "", username: "", password: "", repassword: "", paytype: 1,
                            addstatusacc: 1, creactsubeaccount: false,
                        });
                    } else {
                        this.setState({
                            errorMessage: json.message
                        });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        accname: "", email: "", username: "", password: "", repassword: "", paytype: 1,
                        addstatusacc: 1, creactsubeaccount: false,
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    Getpermission() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({

            });

            apiClient.post('api/usersmanage/Getpermission', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        admin: json.admin,
                        createaccount: json.createaccount,
                        owner: json.owner,
                        createuser: json.createuser,
                        type: json.type,
                        userGenToken: json.userGenToken
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        admin: false,
                        owner: false,
                        createuser: false,
                        createaccount: false,
                        createuser: false,
                        type: 0
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    callbackView(key) {
        this.setState({
            settabs: key,
            subaccount: !this.state.subaccount,
            listview: "block",
            detailview: "none",
        });
    }

    setCreditAccId(id, name) {
        this.setState({
            creditmove: !this.state.creditmove,
            settabs: "creditmovement",
            creditaccId: id,
            creditaccName: name,
        })
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem, action, dateRange, screenwidth,
            MadalAddAccountvisible, accname, email, username, password, paytype, addstatusacc, repassword, passwordcheck,
            creactsubeaccount, createaccount, type, emailError, errorMessage, dsender, owner, admin, createuser, userGenToken, listview, detailview,
            subaccount, settabs, creditaccId, creditaccName, creditmove } = this.state;
        const { onGetcreditbalance } = this.props;

        const routes = [
            {
                path: 'accountmanagement',
                breadcrumbName: 'Account Management',
            },
        ];
        var styleinput_name = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };

        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>
                </PageHeader>

                <div className="content">
                    <Card>

                        {userGenToken && !createaccount && !createuser && !admin ? (
                            <Tabs activeKey={"tokenAuth"} onTabClick={this.callbackView}>
                                <TabPane tab="TOKEN AUTHENICATION" key="tokenAuth">
                                    <TokenAuth />
                                </TabPane>
                            </Tabs>

                        ) : (
                            <Tabs activeKey={settabs} onTabClick={this.callbackView}>
                                <TabPane tab="ACCOUNT" key="account">
                                    <Account admin={admin} setStateByProps={this.setStateByProps} />
                                </TabPane>
                                <TabPane tab="SUB ACCOUNTS" key="subaccount" disabled={createaccount ? false : true}>
                                    <SubAccounts subaccount={subaccount} listview={listview} detailview={detailview}
                                        createaccount={createaccount} type={type} setCreditAccId={this.setCreditAccId} discard={this.callbackView} />
                                </TabPane>
                                <TabPane tab="USERS" key="users" disabled={admin || createuser || createaccount ? false : true}>
                                    <Users admin={admin} owner={owner} createuser={createuser} />
                                </TabPane>
                                <TabPane tab="CREDITS MOVEMENT" key="creditmovement" disabled={admin ? false : true}>
                                    <CreditsMovement creditaccId={creditaccId} creditaccName={creditaccName} creditmove={creditmove} onGetcreditbalance={onGetcreditbalance} />
                                </TabPane>
                                <TabPane tab="NOTIFICATION" key="config" disabled={admin && paytype == 1 ? false : true}>
                                    <ConfigCreditAlert />
                                </TabPane>

                                {userGenToken && (
                                    <TabPane tab="TOKEN AUTHENICATION" key="tokenAuth">
                                        <TokenAuth />
                                    </TabPane>
                                )}

                            </Tabs>
                        )}

                    </Card>

                </div>

                <ModalAddAccount
                    loading={loading}
                    setStateByProps={this.setStateByProps}
                    visible={MadalAddAccountvisible}
                    handleOk={this.handleOk}
                    accname={accname}
                    email={email}
                    username={username}
                    password={password}
                    paytype={paytype}
                    statusacc={addstatusacc}
                    repassword={repassword}
                    warningModal={this.warningModal}
                    passwordcheck={passwordcheck}
                    creactsubeaccount={creactsubeaccount}
                    type={type}
                    dsender={dsender}
                    emailError={emailError}
                    errorMessage={errorMessage}
                />

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div>
        );
    }
}


